<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="280 50 270 341">
    <polygon
      fill="#FFFFFF"
      points="296,210 291,237 312,258 376,386 504,391 520,354.1 536,306 541,199 520,178 488,162 440,146 408,141 392,66 360,66 355,237 323,205"
    />
    <path
      d="M344,66h16v176h-16v-16h-16v-16h16V66z M536,194v112h16V194H536z M280,194v48h16v-32h32v-16H280z M328,290v-32h-16v32H328z M344,322v-32h-16v32H344z M360,354v48h160v-47.9h-16V386H376l0,0v-32H360l0-32h-16v32H360z M520,354h16v-48h-16V354z M312,258v-16 h-16v16H312z M520,178v16h16v-16H520z M488,162v64h16v-48h16v-16H488z M440,146v64h16v-48h32v-16H440z M408,130V66h-16v144h16v-64 h32v-16H408z M360,50v16h32V50H360z"
    />
  </svg>
</template>
