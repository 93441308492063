import { color } from "@formkit/inputs";

export const prompts = [
  {
    label: "Digitize paper form",
    icon: "material-symbols:text-snippet",
    color: "blue",
    value: "Generate the attached Mayo Clinic new patient registration form.",
    attachment: "MayoClinic.png",
    layout: "minimal",
    flow: "single",
  },
  {
    label: "Complex booking form",
    icon: "material-symbols:directions-car-rounded",
    color: "green",
    value:
      "Create a car rental booking form — walk the user through the process step by step.",
    layout: "top-image",
    flow: "multi",
  },
  {
    label: "Form from schema",
    icon: "material-symbols:database",
    color: "purple",
    value:
      "Using the attached database sql, create a form that would populate it. Omit any private / internal fields.",
    attachment: "CreateUser.sql",
    layout: "minimal",
    flow: "single",
  },
  {
    label: "Paid consulting form",
    icon: "material-symbols:coffee",
    color: "orange",
    value:
      "Create a booking form for 1-on-1 career coaching sessions. Sessions should be $150 and last 1 hour.",
    layout: "card",
    flow: "multi",
  },
  {
    label: "Insurance form",
    icon: "fa6-solid:car-crash",
    color: "red",
    value:
      "An insurance claim form for car accidents. Use the attached photo of our company's paper form as a reference",
    attachment: "InsuranceClaimForm.jpeg", // TODO
    layout: "minimal",
    flow: "single",
  },
  {
    label: "Repeating elements",
    icon: "material-symbols:table-rows",
    color: "blue",
    value:
      'Create a registration form for the "Pickleball Rec League" Charlottesville meetup that allows multiple players to be registered at once.',
    layout: "left-image",
    flow: "single",
  },
  {
    label: "GravityForms migration",
    icon: "material-symbols:drive-file-move",
    color: "pink",
    value:
      "Create a form based on the attached Gravity Forms export from my WordPress site.",
    attachment: "gravityforms-export.json",
    layout: "minimal",
    flow: "single",
  },
  {
    label: "Health survey form",
    icon: "material-symbols:ecg-heart-sharp",
    color: "red",
    value:
      "Create a survey that measures the overall quality of a subject's diet.",
    layout: "minimal",
    flow: "max",
  },
  {
    label: "Form from sketch",
    icon: "material-symbols:edit-square-rounded",
    color: "green",
    value: "Create a form based on the attached photo of a whiteboard sketch.",
    attachment: "sketch.jpeg",
    layout: "right-image",
    flow: "single",
  },
  {
    label: "Convert paper form",
    icon: "material-symbols:lab-profile",
    color: "purple",
    value: "I need this DMV license registration form digitized.",
    attachment: "DMV_License_Reg.pdf", // TODO
    layout: "minimal",
    flow: "single",
  },
  {
    label: "Job application form",
    icon: "mdi:briefcase",
    color: "orange",
    value:
      "Create a job application form for a management position at a big box retail store.",
    layout: "top-image",
    flow: "single",
  },
  {
    label: "Civic feedback form",
    icon: "ph:tree-fill",
    color: "green",
    value:
      "Create an form that allows users to submit feedback on a new city park.",
    layout: "left-image",
    flow: "multi",
  },
  {
    label: "RSVP forms",
    icon: "fluent:bow-tie-20-filled",
    color: "pink",
    value:
      "I need an RSVP form for my wedding that allows guests to select their meal preference and add plus ones. Limit plus ones to 2 additional guests.",
    layout: "card",
    flow: "multi",
  },
  {
    label: "Multi-step form",
    icon: "iconoir:report-columns",
    color: "blue",
    value:
      "Create a multi-step form for a new user to submit information for a green energy R&D grant.",
    layout: "top-image",
    flow: "multi",
  },
  {
    label: "Multi-page form",
    icon: "iconoir:report-columns",
    color: "orange",
    value:
      "Create a multi-page form for the Richmond Wildcats Parks & Rec youth soccer tryouts.",
    layout: "left-image",
    flow: "multi",
  },
  {
    label: "Lead generation form",
    icon: "material-symbols:android-camera",
    color: "purple",
    value:
      "I need to be able to capture leads for my freelance wedding photography business.",
    layout: "right-image",
    flow: "single",
  },
  {
    label: "Migrate legacy form",
    icon: "material-symbols:code",
    color: "orange",
    value: "I need to create a copy of the form in the attached HTML file",
    attachment: "submit_proposal.html", // TODO
    layout: "minimal",
    flow: "single",
  },
  {
    label: "Equipment rental form",
    icon: "material-symbols:local-shipping-rounded",
    color: "green",
    value:
      "I need a form that lets customers request equipment for their events. Our rental inventory is attached.",
    attachment: "inventory.csv", // TODO
    layout: "minimal",
    flow: "single",
  },
  {
    label: "Newsletter Signup",
    icon: "mdi:email-outline",
    color: "blue",
    value:
      "An email newsletter sign up for the latest information in the form-building space — with checkboxes for various interests.",
    layout: "minimal",
    flow: "single",
  },
];
