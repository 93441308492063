<script setup lang="ts">
import { ref, onMounted } from "vue";
import { vConfetti } from "@neoconfetti/vue";

const system = useSystemStore();
const { formModalOpen } = storeToRefs(system);

const confettiTarget = ref(null);
const isInView = ref(false);
const launchConfetti = ref(false);
const simulateClick = ref(false);
const hasFired = ref(false);
const hasTriggered = ref(false);
const conclude = ref(false);

// let users manually trigger confetti as
// often as they want
const confettiClicks: Ref<any[]> = ref([]);
const clickCount = ref(0);
const clickLimit = ref(30);

const startSecret = ref(false);

const getConfettiSettings = () => {
  return {
    particleCount: Math.round(10 + clickCount.value / 2),
    particleSize: 60,
    duration: 3200 + clickCount.value * 20,
    force: 0.7 + (clickCount.value + 1) / 50,
    particleShape: "rectangles",
    colors: ["#22c55e", "#16a34a", "#15803d"],
  };
};

onMounted(() => {
  const observer = new IntersectionObserver(
    ([entry]) => {
      if (entry.isIntersecting && !hasTriggered.value) {
        hasTriggered.value = true;

        setTimeout(() => {
          isInView.value = true;
        }, 500);

        setTimeout(() => {
          simulateClick.value = true;
        }, 2000);

        setTimeout(() => {
          launchConfetti.value = true;
          simulateClick.value = false;
          hasFired.value = true;
        }, 2200);

        setTimeout(() => {
          conclude.value = true;
        }, 3000);

        setTimeout(() => {
          launchConfetti.value = false;
        }, 4700);
      }
    },
    {
      rootMargin: "-40%",
    }
  );

  if (confettiTarget.value) {
    observer.observe(confettiTarget.value);
  }
});

const handlePaymentClick = () => {
  if (conclude.value && clickCount.value < clickLimit.value) {
    simulateClick.value = true;
    confettiClicks.value.push(Object.assign({}, getConfettiSettings()));
    clickCount.value += 1;

    setTimeout(() => {
      simulateClick.value = false;
    }, 80);
  }

  if (clickCount.value === clickLimit.value) {
    startSecret.value = true;
  }
};
</script>

<template>
  <MarketingPageSection class="border-t border-blue-100">
    <div class="flex flex-col lg:flex-row gap-8 items-start justify-between">
      <div class="lg:w-1/2 mb-8 lg:mb-0">
        <h2
          class="text-3xl md:text-4xl xl:text-5xl font-bold mb-4 text-blue-900 text-balance"
        >
          Accept <span class="text-green-500">payments</span>
          directly in your forms.
        </h2>
        <p class="text-slate-500 text-lg lg:text-xl leading-tight">
          Dog sitting, music lessons, paid consulting appointments.<br />
          Whatever you need to collect payments for, you can do it with FormKit.
          <br />
          No merchant account required — we handle everything.
        </p>
        <Button
          class="mt-6 mx-auto !bg-green-500 hover:!bg-black"
          @click="() => (formModalOpen = true)"
        >
          <Icon name="lucide:edit" class="mr-2 h-4 w-4" />
          Start Collecting Payments Now
        </Button>
      </div>
      <div class="relative w-full lg:w-1/2">
        <div
          :class="`
            absolute
            top-1/3
            left-1/2
            -translate-x-1/2
            -translate-y-1/2
            border-[1em]
            ring-1
            ring-slate-200
            border-white
            shadow-md
            shadow-blue-100
            -rotate-2
            rounded-sm

            after:absolute
            after:bg-yellow-700/10
            after:border
            after:border-white/80
            after:shadow-sm
            after:w-32
            after:h-10
            after:-top-8
            after:left-1/2
            after:-translate-x-1/2
            after:-rotate-2
            after:rounded-sm
          `"
        >
          <img
            :src="startSecret ? '/suprise.webp' : ''"
            loading="lazy"
            :class="`
              relative
              w-full
              bg-slate-200
            `"
          />

          <span
            v-if="startSecret"
            class="text-xs text-center tracking-tighter font-semibold w-full block py-2 text-slate-400"
            >PROMO CODE: CHEDDAR</span
          >
        </div>
        <div
          :class="`
          relative
          w-full
          bg-white
          border
          border-slate-300
          rounded-lg
          shadow-md
          shadow-blue-100
          translate-y-0
          transition-all
          duration-200
          z-10
          select-none

          data-[simulate-click=true]:translate-y-2

          data-[aaa=true]:translate-y-[5px]
          data-[aaa=true]:ease-in-out
          data-[aaa=true]:-rotate-[1.5deg]
          data-[aaa=true]:duration-300
          data-[aaa=true]:data-[simulate-click=true]:translate-y-[16px]

          data-[bbb=true]:translate-y-[40px]
          data-[bbb=true]:ease-in-out
          data-[bbb=true]:rotate-[3deg]
          data-[bbb=true]:duration-300
          data-[bbb=true]:data-[simulate-click=true]:translate-y-[20px]
          
          data-[ccc=true]:!translate-y-[100%]
          data-[ccc=true]:!ease-in-out
          data-[ccc=true]:!rotate-[20deg]
          data-[ccc=true]:!duration-200
        `"
          ref="confettiTarget"
          :data-simulate-click="simulateClick"
          :data-aaa="
            clickCount >= clickLimit / 3 && clickCount < clickLimit / 1.5
          "
          :data-bbb="clickCount >= clickLimit / 1.5 && clickCount < clickLimit"
          :data-ccc="startSecret"
        >
          <div
            class="absolute -top-10 left-1/2 pointer-events-none"
            v-confetti="
              launchConfetti ? getConfettiSettings() : { particleCount: 0 }
            "
          />
          <div
            class="absolute -top-10 left-1/2 pointer-events-none"
            v-for="(click, index) in confettiClicks"
            :key="click"
            v-confetti="confettiClicks[index]"
          />
          <MarketingPointerCursor
            :class="`
            absolute 
            left-1/2
            top-[calc(100%-2em)]
            opacity-0
            -translate-x-1/2
            translate-y-full
            w-16
            z-10
            transition-all
            duration-1000
            transform-origin-top

            data-[in-view=true]:-translate-y-2
            data-[in-view=true]:opacity-100
            data-[simulate-click=true]:duration-200
            data-[simulate-click=true]:-translate-y-3
            data-[simulate-click=true]:scale-[0.8]
            data-[conclude=true]:!translate-y-full
            data-[conclude=true]:!opacity-0
          `"
            :data-in-view="isInView"
            :data-simulate-click="simulateClick"
            :data-conclude="conclude"
          />
          <div class="relative p-5">
            <div
              class="hidden sm:flex rotate-12 absolute top-2]3 right-5 border-2 border-slate-100 aspect-square p-3 rounded-full bg-white"
            >
              <Icon
                name="pajamas:food"
                class="block h-12 w-12 text-slate-300/80"
              />
            </div>
            <div>
              <div class="text-slate-500 leading-tight flex flex-col">
                <span class="text-3xl font-bold text-slate-600">
                  Bob's Burgers
                </span>
                <span class="text-xl font-bold text-slate-400 mb-5">
                  Couple's Cooking Class
                </span>
                <div class="space-y-3">
                  <div class="h-3 bg-slate-300 rounded w-1/2 b"></div>
                  <div
                    class="h-10 bg-slate-100 border border-slate-200 rounded"
                  ></div>
                </div>

                <!-- Payment section -->
                <div class="mt-6 space-y-3">
                  <div class="h-3 bg-slate-300 rounded w-1/3"></div>
                  <div class="flex space-x-2">
                    <div
                      class="h-10 bg-slate-100 border border-slate-200 rounded w-1/4"
                    ></div>
                    <div
                      class="h-10 bg-slate-100 border border-slate-200 rounded w-1/4"
                    ></div>
                    <div
                      class="h-10 bg-slate-100 border border-slate-200 rounded w-1/4"
                    ></div>
                    <div
                      class="h-10 bg-slate-100 border border-slate-200 rounded w-1/4"
                    ></div>
                  </div>
                  <div class="flex space-x-2">
                    <div
                      class="h-10 bg-slate-100 border border-slate-200 rounded flex-grow"
                    ></div>
                    <div
                      class="h-10 bg-slate-100 border border-slate-200 rounded w-[15%]"
                    ></div>
                  </div>
                </div>

                <!-- Submit button -->
                <div class="mt-6">
                  <div
                    class="h-12 transition-colors bg-green-500 hover:bg-green-600 rounded w-full flex items-center justify-center gap-4 text-green-100 hover:text-green-50 data-[conclude=true]:cursor-pointer select-none data-[simulate-click=true]:bg-green-700"
                    @click="handlePaymentClick"
                    :data-simulate-click="simulateClick"
                    :data-conclude="conclude"
                  >
                    <Icon name="f7:creditcard" class="h-6 w-6" />
                    <span class="text-lg">Purchase</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </MarketingPageSection>
</template>
