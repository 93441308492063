<script setup lang="ts">
import { ref } from "vue";

const system = useSystemStore();
const { formModalOpen } = storeToRefs(system);

const labels = [
  "Minimal",
  "Card over Image",
  "Left Decorative Image",
  "Top Decorative Image",
  "Right Decorative Image",
];

const baseClasses = {
  wrapper:
    "transition-all duration-300 absolute bg-white flex justify-center !pb-0",
  inner: "relative transition-all duration-300 w-full h-full overflow-clip",
};

const themeClasses = [
  {
    _name: "minimal",
    wrapper:
      "-top-[3px] -left-[3px] w-[calc(100%+6px)] h-[calc(100%+6px)] rounded-sm",
    inner: "px-24 pt-16",
  },
  {
    _name: "card",
    wrapper:
      "top-[15%] left-[15%] w-[calc(70%+6px)] h-[calc(70%+6px)] rounded-lg shadow shadow-purple-700 ring-1 ring-purple-300",
    inner: "p-12",
  },
  {
    _name: "left",
    wrapper:
      "rounded-sm -top-[3px] left-[25%] h-[calc(100%+6px)] w-[calc(75%+6px)] shadow-md shadow-purple-700/40",
    inner: "p-12",
  },
  {
    _name: "top",
    wrapper:
      "rounded-sm -left-[3px] top-[25%] h-[calc(100%+6px)] w-[calc(100%+6px)] shadow-xl shadow-purple-800 ring-1 ring-purple-400",
    inner: "px-24 pt-12",
  },
  {
    _name: "right",
    wrapper:
      "rounded-sm -left-[3px] -top-[3px] h-[calc(100%+6px)] w-[calc(75%+6px)] shadow-md shadow-purple-700/40",
    inner: "p-12",
  },
];

const currentImageIndex = ref(0);
const isAutoAdvancing = ref(true);
const timePerSlide = 2500;
const timeRemaining = ref(3000);
const intervalId = ref<any>(null);
const sectionRef = ref<HTMLElement | null>(null);
const isVisible = ref(true);

const setCurrentImage = (index: number) => {
  currentImageIndex.value = index;
  pauseAutoAdvance();
  resetTimer();
};

const resetTimer = () => {
  timeRemaining.value = timePerSlide;
  if (isAutoAdvancing.value && isVisible.value) {
    clearInterval(intervalId.value!);
    startTimer();
  }
};

const startTimer = () => {
  intervalId.value = setInterval(() => {
    if (timeRemaining.value > 0) {
      timeRemaining.value -= 20;
    } else {
      resetTimer();
      currentImageIndex.value = (currentImageIndex.value + 1) % labels.length;
    }
  }, 20);
};

const pauseAutoAdvance = () => {
  isAutoAdvancing.value = false;
  clearInterval(intervalId.value!);
};

const resumeAutoAdvance = () => {
  isAutoAdvancing.value = true;
  resetTimer();
};

const handleVisibilityChange = (entries: IntersectionObserverEntry[]) => {
  isVisible.value = entries[0].isIntersecting;
  if (isVisible.value) {
    resumeAutoAdvance();
  }
};

onMounted(() => {
  const observer = new IntersectionObserver(handleVisibilityChange, {
    threshold: 0.1, // Trigger when 50% of the section is visible
  });

  if (sectionRef.value) {
    observer.observe(sectionRef.value);
  }

  startTimer();
});

onUnmounted(() => {
  clearInterval(intervalId.value!);
});
</script>

<template>
  <MarketingPageSection class="border-t border-blue-100">
    <div
      ref="sectionRef"
      class="flex flex-col lg:flex-row gap-8 items-start justify-between"
    >
      <div class="w-full lg:w-1/2 mb-8 lg:mb-0 order-2 lg:order-1">
        <div
          class="relative w-full aspect-video bg-gradient-to-b from-purple-300 to-purple-600/80 border border-slate-300 rounded-lg overflow-clip"
        >
          <div
            :class="`${baseClasses.wrapper} ${themeClasses[currentImageIndex].wrapper}`"
          >
            <div
              :class="`${baseClasses.inner} ${themeClasses[currentImageIndex].inner}`"
            >
              <div class="space-y-3 mb-4">
                <div class="h-2 bg-slate-300 rounded w-1/2 b"></div>
                <div
                  class="h-8 bg-slate-100 border border-slate-200 rounded"
                ></div>
              </div>
              <div class="space-y-3 grid grid-cols-2 gap-x-2 mb-4">
                <div class="h-2 bg-slate-300 rounded w-2/3 col-span-2"></div>
                <div
                  class="h-10 bg-slate-100 border border-slate-200 rounded"
                ></div>
                <div
                  class="h-10 bg-slate-100 border border-slate-200 rounded"
                ></div>
              </div>
              <div class="space-y-3 grid grid-cols-3 gap-x-2">
                <div class="h-2 bg-slate-300 rounded w-1/3 col-span-3"></div>
                <div
                  class="h-24 bg-slate-100 border border-slate-200 rounded col-span-3"
                ></div>
              </div>
            </div>
          </div>

          <span
            class="absolute bottom-2 left-2 bg-white border border-slate-300 shadow-sm shadow-purple-400/30 text-slate-600 text-sm rounded px-2 py-0.5"
          >
            {{ labels[currentImageIndex] }}
          </span>
        </div>
        <div class="grid grid-cols-5 gap-2 pt-4 px-6 mx-auto max-w-screen-sm">
          <button
            v-for="(image, index) in labels"
            :key="image"
            class="group transition-all duration-100 relative w-full aspect-[8/7] bg-white rounded-md overflow-hidden ring-1 ring-slate-300 focus:outline-none active:ring-2 active:ring-purple-500 data-[active=true]:ring-2 data-[active=true]:ring-purple-500"
            :data-active="index === currentImageIndex"
            @click="setCurrentImage(index)"
          >
            <template v-if="index === 0">
              <div class="inset-0 bg-white w-full h-full"></div>
            </template>
            <template v-if="index === 1">
              <div
                class="absolute inset-0 bg-slate-200/30 group-data-[active=true]:bg-purple-100"
              >
                <div
                  class="absolute inset-5 rounded-lg bg-white border border-slate-300 group-data-[active=true]:bg-white group-data-[active=true]:border-purple-300 transition-all duration-300"
                />
              </div>
            </template>
            <template v-if="index === 2">
              <div
                class="absolute left-0 top-0 w-1/3 h-full bg-slate-200/30 border-r group-data-[active=true]:bg-purple-100 group-data-[active=true]:border-purple-300 transition-all duration-300"
              />
            </template>
            <template v-if="index === 3">
              <div
                class="absolute top-0 left-0 w-full h-1/3 bg-slate-200/30 border-b group-data-[active=true]:bg-purple-100 group-data-[active=true]:border-purple-300 transition-all duration-300"
              />
            </template>

            <template v-if="index === 4">
              <div
                class="absolute right-0 top-0 w-1/3 h-full bg-slate-200/30 border-l group-data-[active=true]:bg-purple-100 group-data-[active=true]:border-purple-300 transition-all duration-300"
              />
            </template>

            <span
              class="absolute bottom-0 left-0 h-1.5 bg-purple-500"
              :style="{
                width:
                  index === currentImageIndex
                    ? `${100 - (timeRemaining / timePerSlide) * 100}%`
                    : '0%',
              }"
            />
          </button>
        </div>
      </div>
      <div class="w-full lg:w-1/2 mb-8 lg:mb-0 order-1 lg:order-2">
        <h2
          class="text-3xl md:text-4xl xl:text-5xl font-bold mb-4 text-blue-900"
        >
          Anyone can make these forms look
          <span class="text-purple-500">good</span>.<br />
        </h2>
        <p class="text-slate-500 text-lg lg:text-xl leading-tight">
          Simple layout options and no-nonsense theme controls mean you're
          always putting your best foot forward.
        </p>
        <Button
          class="mt-6 mx-auto !bg-purple-500 hover:!bg-black"
          @click="() => (formModalOpen = true)"
        >
          <Icon name="lucide:edit" class="mr-2 h-4 w-4" />
          Surprise yourself
        </Button>
      </div>
    </div>
  </MarketingPageSection>
</template>
