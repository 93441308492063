<script setup lang="ts">
interface Prompt {
  label: string
  value: string
  attachment?: string
  icon?: string
  color: string
}

interface Props {
  prompts: Prompt[]
  direction?: "forwards" | "backwards"
}

const animate = inject<boolean>("animate")

const props = withDefaults(defineProps<Props>(), {
  direction: "forwards",
})

const duplicatedPrompts = computed(() => [
  ...props.prompts,
  ...props.prompts,
  ...props.prompts,
  ...props.prompts,
  ...props.prompts,
])
</script>

<template>
  <div
    class="scrolling-cards-container lg:min-w-[300px] shrink-0"
    :data-direction="direction"
    :data-animate="animate"
  >
    <div
      class="scrolling-cards p-1 flex flex-col gap-2 sm:gap-3 md:gap-4 lg:gap-5 xl:gap-6 2xl:gap-8"
    >
      <template
        v-for="(prompt, index) in duplicatedPrompts"
        :key="`${prompt.value}-${index}`"
      >
        <MarketingPromptCard :prompt="prompt as Prompt" />
      </template>
    </div>
  </div>
</template>

<style scoped>
@keyframes scrollY {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20%);
  }
}

.scrolling-cards-container {
  position: relative;
}

.scrolling-cards {
  animation: scrollY 60s linear infinite;
}

[data-direction="backwards"] .scrolling-cards {
  animation-direction: reverse;
}

[data-animate="false"] .scrolling-cards {
  animation-play-state: paused;
}
</style>
