<script setup lang="ts">
const props = defineProps({
  prompt: {
    type: Object,
    required: true,
  },
});

const marketing = useMarketingStore();
const { selectedPrompt } = storeToRefs(marketing);

const colorClasses = {
  blue: "bg-blue-500",
  green: "bg-green-500",
  red: "bg-red-500",
  orange: "bg-orange-500",
  purple: "bg-purple-500",
  pink: "bg-pink-500",
};

const attachmentPreviewImage = computed(() => {
  if (props?.prompt?.attachment) {
    const segments = props?.prompt?.attachment.split(".");
    const fileName = segments[0];
    return `/example-preview/${fileName}.png`;
  }
  return "";
});

function setSelectedPrompt() {
  selectedPrompt.value = props.prompt;
}
</script>

<template>
  <Component
    :is="attachmentPreviewImage ? 'VTooltip' : 'div'"
    :triggers="['hover']"
    :placement="'left'"
    :distance="8"
    theme="dropdown"
    @click="setSelectedPrompt"
  >
    <template v-if="attachmentPreviewImage" #popper>
      <div class="p-2 min-w-[200px] min-h-[200px]">
        <img
          :src="attachmentPreviewImage"
          loading="lazy"
          class="max-w-[500px] max-h-[500px] rounded overflow-clip"
        />
      </div>
    </template>

    <div
      class="p-5 bg-white border border-blue-200 shadow-md shadow-blue-100 rounded-lg cursor-pointer hover:outline hover:outline-2 hover:outline-pink-500 overflow-clip"
    >
      <div class="flex gap-2 mb-2 items-center">
        <div
          :class="`${
            colorClasses[prompt.color as keyof typeof colorClasses]
          } p-1 rounded text-white w-7 aspect-square flex`"
        >
          <Icon :name="prompt.icon" class="m-auto" />
        </div>
        <h3 class="font-semibold text-slate-600 mb-0 leading-4">
          {{ prompt.label }}
        </h3>
      </div>
      <p class="text-slate-500 text-xs">{{ prompt.value }}</p>

      <template v-if="prompt.attachment">
        <span class="text-xs text-slate-500 mr-1 font-bold">Attachment:</span>
        <span class="text-xs text-blue-600 underline whitespace-nowrap">{{
          prompt.attachment
        }}</span>
      </template>
    </div>
  </Component>
</template>
