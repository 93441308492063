const logos = [
  "nbc",
  "nike",
  "lucasfilm",
  "hagerty",
  "bluecross",
  "uva",
  "swisscom",
  "osu",
  "wmt",
  "evident-change",
  "leapsome",
  "workshop",
];

const tweets = [
  {
    text: "The team from @FormKit are awesome. If you’re not working with them, you’re doing it wrong.",
    medium: "twitter",
    author: "@adamdehaven",
    byline: "Staff Engineer at Kong Inc.",
    avatar: "adam.jpg",
    url: "https://twitter.com/adamdehaven/status/1763502369071931630",
  },
  {
    text: "After using @FormKit for a few weeks I just can't recommend it enough. Quality + Flexibility + Productivity 🚀",
    medium: "twitter",
    author: "@IsraelOrtuno",
    byline: "VueJobs Co-Founder",
    avatar: "israel.jpg",
    url: "https://twitter.com/IsraelOrtuno/status/1729640346898809222",
  },
  {
    text: "It's insane how good FormKit is!",
    medium: "website",
    author: "Michał Kuncio",
    byline: "Modern webdev",
    avatar: "michal.png",
    url: "https://michalkuncio.com/its-insane-how-good-formkit-is/",
  },
  {
    text: "[...] @FormKit is the clear favourite here.",
    medium: "twitter",
    author: "@MichaelThiessen",
    byline: "Vue Tips & MasteringNuxt",
    avatar: "michael.jpg",
    url: "https://twitter.com/MichaelThiessen/status/1768593973994512803",
  },
  {
    text: "I just found out about @FormKit [...]. Such a breeze.",
    medium: "twitter",
    author: "@sam_odo",
    byline: "Working on chatsome.co.",
    avatar: "sam.jpg",
    url: "https://twitter.com/sam_odo/status/1695688064197075206",
  },
  {
    text: "FormKit has allowed us to streamline the process of creating complicated forms and reusing these across several projects, saving us tons of development time!",
    medium: "twitter",
    author: "@zoey_kaiser",
    byline: "Developer @sidebase_io",
    avatar: "zoey.jpg",
    url: "https://twitter.com/zoey_kaiser/status/1788996414304924096",
  },
  {
    text: "FormKit is pretty much just, “take my money”.",
    medium: "Slack",
    author: "Justin Beck",
    byline: "Staff Engineer @ Astraea Inc",
    avatar: "justin.jpeg",
  },
  {
    text: "Been there done that. Which is why we now use FormKit :)",
    medium: "twitter",
    author: "@JoshDeltener",
    byline: "Instructor @MasteringNuxt",
    avatar: "josh.jpg",
    url: "https://twitter.com/JoshDeltener/status/1768652138090053814",
  },
  {
    text: "My application has quite a few forms and FormKit saves my butt every time. [...] If I had to write those forms by hand from scratch, I'd consider quitting the job.",
    medium: "reddit",
    author: "csakiss",
    avatar: "csakiss.webp",
    url: "https://www.reddit.com/r/vuejs/comments/18hk9ff/comment/kd99r2z/",
  },
  {
    text: 'Well <span class="eraser-1"><span class="inline-block opacity-[25%] blur-[2px] scale-y-110 tracking-widest underline">fli̷̖̒p̷͂p̴i̷͑n̷͑g</span></span> <span class="eraser-2"><span class="inline-block opacity-[25%] blur-[2px] scale-y-110 underline">he̶̠̾c̸͈̰̄k̵̊͌</span></span> color me impressed! 😲. You can use that as a testimonial if you want.',
    medium: "Slack",
    author: "Seth Atwood",
    byline: "Fullstack Engineer",
    avatar: "seth.jpeg",
  },
  {
    text: "Ok how come no one told me about Formkit? This has a bunch of the stuff I was looking for",
    medium: "twitter",
    author: "@hotmeteor",
    byline: "Co-founder of @SolenoidStudios",
    avatar: "hotmeteor.jpg",
    url: "https://twitter.com/hotmeteor/status/1760799536904122666",
  },
  {
    text: "For the last project which is a form-heavy project, I used @FormKit and I feel mentally healthier!",
    medium: "twitter",
    author: "@Alireza_Jahan",
    byline: "co-creator @faculty_bio",
    avatar: "alireza.jpg",
    url: "https://twitter.com/Alireza_Jahan/status/1733910882592403960",
  },
  {
    text: "FormKit is probably the best way to handle forms :)",
    medium: "twitter",
    author: "@SuhailKakar",
    byline: "Devrel at Livepeer",
    avatar: "suhail.jpg",
    url: "https://twitter.com/SuhailKakar/status/1733897769272967646",
  },
  {
    text: "[...] The @FormKit team doesn’t miss!",
    medium: "twitter",
    author: "@crutchcorn",
    byline: "@tan_stack Maintainer",
    avatar: "crutchcorn.jpg",
    url: "https://twitter.com/crutchcorn/status/1757495167698030602",
  },
];

export { logos, tweets };
