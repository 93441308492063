<script setup lang="ts">
const system = useSystemStore();
const { formModalOpen } = storeToRefs(system);

const stableId = useId();

const integrations = [
  {
    name: "Google Sheets",
    file: "googlesheets.svg",
  },
  {
    name: "Airtable",
    file: "airtable.svg",
  },
  {
    name: "Notion",
    file: "notion.svg",
  },
  {
    name: "Google Drive",
    file: "googledrive.svg",
  },
  {
    name: "Salesforce",
    file: "salesforce.svg",
  },
  {
    name: "Slack",
    file: "slack.svg",
  },
  {
    name: "Discord",
    file: "discord.svg",
  },
  {
    name: "Zapier",
    file: "zapier.svg",
  },
];
</script>

<template>
  <MarketingPageSection class="border-t border-blue-100">
    <div class="flex flex-col gap-3 md:gap-8 items-start justify-between">
      <div class="w-full mb-8 lg:mb-0">
        <h2
          class="text-center text-balance max-w-screen-md mx-auto text-3xl md:text-4xl xl:text-5xl font-bold mb-4 text-blue-900"
        >
          Send your form data <span class="text-yellow-500">wherever</span>
          it needs to go.
        </h2>
        <p
          class="text-center text-balance mx-auto max-w-screen-md text-slate-500 text-lg lg:text-xl leading-tight"
        >
          FormKit has 1st-party integrations with the most popular services and
          many more 3rd-party options are available through Zapier.
        </p>
      </div>
      <div
        class="w-full max-w-80 sm:max-w-screen-sm mx-auto grid grid-cols-2 sm:grid-cols-4 gap-5 p-5"
      >
        <template v-for="i in integrations">
          <VTooltip
            :triggers="['hover']"
            :placement="'top'"
            :distance="8"
            :aria-id="stableId"
          >
            <template #popper>
              <div>{{ i.name }}</div>
            </template>
            <div
              class="bg-white p-5 w-full aspect-square border border-slate-200 rounded-lg shadow-md shadow-blue-100 flex"
            >
              <img
                :src="`/integrations/${i.file}`"
                :alt="i.name"
                class="m-auto max-w-16 aspect-square"
              />
            </div>
          </VTooltip>
        </template>
      </div>

      <Button
        class="mt-2 mx-auto !bg-yellow-500 hover:!bg-black"
        @click="() => (formModalOpen = true)"
      >
        <Icon name="lucide:edit" class="mr-2 h-4 w-4" />
        Build connected forms
      </Button>
    </div>
  </MarketingPageSection>
</template>
