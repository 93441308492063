<script lang="ts" setup></script>

<template>
  <div class="flex flex-col w-full overflow-clip">
    <MarketingHomeHero />
    <MarketingCredibility />
    <MarketingGetPaid />
    <MarketingNoDesign />
    <MarketingNoCode />
    <MarketingIntegrations />
    <MarketingSocialProof />
    <MarketingLastCall />
  </div>
</template>
