<template>
  <MarketingPageSection class="!bg-slate-800">
    <div class="flex flex-col gap-8 items-start justify-between pb-8">
      <div class="w-full mb-8 lg:mb-6">
        <h2
          class="text-center text-balance max-w-screen-[800px] mx-auto text-3xl md:text-4xl xl:text-5xl font-bold mb-4 text-blue-100"
        >
          The easiest form you'll ever make <br />
          <span class="text-pink-600 inline-block text-balance"
            >because you don't have to make it.</span
          >
        </h2>
        <p
          class="text-center text-balance mx-auto max-w-screen-md text-slate-400 text-lg lg:text-xl leading-tight"
        >
          From concept to published — in seconds — with no code.<br />
          Try it now, for free! 👇
        </p>
      </div>
      <div
        :class="`
          relative
          mx-auto
          w-full
          max-w-[550px]
        `"
      >
        <NewFormInput
          input-id="last-call"
          :use-dynamic-placeholder="true"
          :shadow="false"
        />
      </div>
    </div>
  </MarketingPageSection>
</template>
