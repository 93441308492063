<script setup lang="ts">
import { prompts } from "@/assets/prompts"

const randomizedPrompts = prompts.sort(() => Math.random() - 0.5)

const animate = ref(true)

const columns = [
  randomizedPrompts.slice(0, Math.ceil(randomizedPrompts.length / 3)),
  randomizedPrompts.slice(
    Math.ceil(randomizedPrompts.length / 3),
    Math.ceil(randomizedPrompts.length / 3) * 2
  ),
  randomizedPrompts.slice(Math.ceil(randomizedPrompts.length / 3) * 2),
]

provide("animate", animate)
</script>

<template>
  <div
    :class="`
      relative
      grid
      grid-cols-1
      gap-1
      sm:gap-2
      md:gap-3
      xl:gap-4
      2xl:gap-8
      overflow-clip
      shrink-0
      w-full
      max-h-[50dvh]
      -mt-10

      sm:grid-cols-2
      md:grid-cols-3

      lg:absolute
      lg:inset-0
      lg:pr-2
      lg:grid-rows-1
      lg:-mr-[10vw]
      lg:-mb-20
      lg:min-w-[940px]
      lg:max-h-none

      before:absolute
      before:w-full
      before:bottom-0
      before:h-10
      before:bg-gradient-to-t
      before:from-gray-50
      before:to-transparent
      before:z-10
      before:pointer-events-none

      after:absolute
      after:w-full
      after:top-0
      after:h-10
      after:bg-gradient-to-b
      after:from-gray-50
      after:to-transparent
      after:z-10
      after:pointer-events-none
    `"
    @mouseover="animate = false"
    @mouseleave="animate = true"
  >
    <ClientOnly>
      <MarketingPromptCardColumn :prompts="columns[0]" />
      <MarketingPromptCardColumn
        :prompts="columns[1]"
        direction="backwards"
        class="hidden sm:block"
      />
      <MarketingPromptCardColumn
        :prompts="columns[2]"
        class="hidden md:block"
      />
    </ClientOnly>
  </div>
</template>
