<script setup lang="ts">
const headlineWords = ref([
  "booking forms",
  "intake forms",
  "job applications",
  "insurance forms",
  "contact forms",
  "feedback surveys",
  "intake forms",
  "user surveys",
  "multi-step forms",
  "multi-page forms",
  "form migrations",
  "digitized paper forms",
  "RSVP forms",
  "project request forms",
  "payment forms",
]);
</script>

<template>
  <MarketingPageSection background="glow-tl" class="pt-20 pb-8 -mt-[60px]">
    <div class="flex flex-col w-full lg:gap-8 lg:flex-row">
      <div
        class="flex flex-col mx-auto mt-16 lg:m-0 w-full max-w-[600px] lg:w-3/5 lg:min-w-3/5 overflow-visible grow"
      >
        <h1
          class="font-header text-4xl sm:text-5xl xl:text-6xl lg:mt-12 xl:mt-6 font-bold mb-4 text-balance text-blue-900 dark:text-blue-50 max-w-full overflow-visible"
        >
          Create beautiful <br />
          <TextRotator :words="headlineWords" class="text-pink-600" /><br />
          instantly.
        </h1>
        <p
          class="text-balance text-lg sm:text-xl xl:text-2xl mb-8 text-slate-600 dark:text-gray-200"
        >
          Any form — ready in seconds.<br />Try it yourself, for
          <NuxtLink class="underline hover:text-green-600" to="/pricing"
            >free</NuxtLink
          >! 👇
        </p>

        <div class="flex flex-col items-center gap-4 mb-16">
          <NewFormInput :focus-on-mount="true" :prompt-target="true">
            <template #post>
              <span
                class="inline-block w-full text-sm sm:text-base text-right sm:max-w-xl text-slate-500 mt-3"
                >Need inspiration? Click one
                <span class="hidden lg:inline">👉</span
                ><span class="inline lg:hidden">👇</span></span
              >
            </template>
          </NewFormInput>
        </div>
      </div>

      <div
        class="flex justify-end lg:w-2/5 lg:max-w-2/5 lg:-mt-40 lg:-mb-20 relative shrink"
      >
        <MarketingPromptCardColumns />
      </div>
    </div>
  </MarketingPageSection>
</template>
