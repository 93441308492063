<script setup lang="ts">
const system = useSystemStore();
const { formModalOpen } = storeToRefs(system);
</script>

<template>
  <MarketingPageSection class="border-t border-blue-100">
    <div class="flex flex-col lg:flex-row gap-8 items-start justify-between">
      <div class="lg:w-1/2 mb-8 lg:mb-0 lg:mt-8">
        <h2
          class="text-3xl md:text-4xl xl:text-5xl font-bold mb-4 text-blue-900"
        >
          It's not just <span class="text-emerald-500">No Code</span>.<br />
          It's <span class="text-emerald-500">No Effort</span>.
        </h2>
        <p class="text-slate-500 text-lg lg:text-xl leading-tight">
          Go from zero to published in seconds.<br />
          Host with us or embed on your own site.<br />
          It doesn't get easier than this.
        </p>
      </div>
      <div
        :class="`
          relative
          lg:w-1/2
          p-5
          bg-white
          border
          border-slate-200
          rounded-lg
          shadow-md
          shadow-blue-100
          rotate-2
          lg:-ml-5

          before:absolute
          before:bg-yellow-700/10
          before:border
          before:border-white/80
          before:shadow-sm
          before:w-32
          before:h-10
          before:bottom-0
          before:-left-10
          before:rotate-45
          before:rounded-sm

          after:absolute
          after:bg-yellow-700/10
          after:border
          after:border-white/80
          after:shadow-sm
          after:w-32
          after:h-10
          after:top-0
          after:-right-10
          after:rotate-[30deg]
          after:rounded-sm
        `"
      >
        <div class="mx-auto">
          <div>
            <div class="text-slate-500 leading-tight flex flex-col">
              <h3 class="text-2xl font-bold mb-4 text-slate-600">
                How to build a form with FormKit
              </h3>
              <ol>
                <li class="mb-4 flex items-center">
                  <span
                    class="flex h-8 aspect-square mr-3 items-center justify-center rounded-md bg-emerald-500 font-semibold text-emerald-100"
                    >1</span
                  >
                  Provide some text, an image, or a reference file and watch
                  your unique form generate in real-time. ✨
                </li>
                <li class="mb-4 flex items-center">
                  <span
                    class="flex h-8 aspect-square mr-3 items-center justify-center rounded-md bg-emerald-500 font-semibold text-emerald-100"
                    >2</span
                  >Make any changes — or if you're happy just hit publish. 🚀
                </li>
                <li class="mb-4 flex items-center">
                  <span
                    class="flex h-8 aspect-square mr-3 items-center justify-center rounded-md bg-emerald-500 font-semibold text-emerald-100"
                    >3</span
                  >Share your link or grab the embed code. 🎉
                </li>
                <li class="flex items-center">
                  <span
                    class="flex h-8 aspect-square mr-3 items-center justify-center rounded-md bg-emerald-500 font-semibold text-emerald-100"
                    >4</span
                  >(optional) Spend some downtime browsing social media
                  — because no one expects you to be done already. 👀
                </li>
              </ol>

              <Button
                class="mt-6 mx-auto !bg-emerald-500 hover:!bg-black"
                @click="() => (formModalOpen = true)"
              >
                <Icon name="lucide:edit" class="mr-2 h-4 w-4" />
                Do nothing — now!
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </MarketingPageSection>
</template>
